/* You can add global styles to this file, and also import other style files */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "./assets/css/app/layout.scss";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeflex/primeflex.css";



@tailwind base;
@tailwind components;
@tailwind utilities;

.p-checkbox-box{
    @apply border-2 border-gray-500
}
.totp .p-toast{
    @apply lg:w-[40rem]
}
.p-toast-message-info{
    @apply bg-white shadow-2xl shadow-[#8f48d2] text-black p-5
}
.p-toast-message-info h1{
    @apply text-center w-full
}
.p-toast-message-info .p-toast-message-content{
    @apply flex-col
}

.p-toast-icon-close{
    @apply hidden
}

.otp-toast .p-toast{
    @apply min-w-[20rem]
}
.otp-toast .totp-toast{
    @apply bg-white bg-opacity-5 text-[#8f48d2] flex items-center justify-center p-[1rem]
}
.otp-toast .totp-toast .p-toast-message-icon{
    @apply text-[#8f48d2] flex items-center justify-center
}
